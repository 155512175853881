import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// https://swiperjs.com/swiper-api#modules

import styles from './index.module.css';

const Main = () => {
  const whatService = [
    { src: './images/main/what-service_1.png', title: '비대면 진료', subtitle: '스마트폰만 있으면 집, 회사, 카페 등 언제 어디서나\n비대면으로 간편하게 진료를 볼 수 있어요.' },
    { src: './images/main/what-service_2.png', title: '조제약 배달', subtitle: '약국에 직접 가지 않아도 퀵으로, 택배로 조제약을 배달 받아요!\n처방전 발급으로 인근 약국에서 직접 수령도 가능합니다.' },
    { src: './images/main/what-service_3.png', title: '맞춤 영양제 설계', subtitle: '기능의학 전문의가 직접 상담에서 영양제 설계까지!\n내 몸에 꼭 맞는 영양제를 설계받아 보세요.' },
    { src: './images/main/what-service_4.png', title: '건강 상품권', subtitle: '소중하고 고마운 이들에게 건강 상품권을 선물해보세요!\n잔액 관리부터 사용까지 간편하게 가능합니다.' },
    { src: './images/main/what-service_5.png', title: '복약 관리', subtitle: '처방받은 약을 올바르게 복용할 수 있도록 바로필이 알려드립니다.\n손쉽게 복약 관리를 해보세요.' }
  ];
  const howTo = [
    { src: './images/main/how-to_1.png', subtitle: '1. 진료실에서 상담을 원하는 진료과목을 선택해주세요.' },
    { src: './images/main/how-to_2.png', subtitle: '2. 진료 요청서 작성 후, 전화 통화로 진료가 이루어집니다.' },
    { src: './images/main/how-to_3.png', subtitle: '3. 진료가 끝나면 처방전을 받아 가까운 약국에 조제 요청을 보내세요.' },
    { src: './images/main/how-to_4.png', subtitle: '4. 약사님이 요청을 수락하면 결제해주세요. 곧 배송이 시작됩니다.' }
  ]

  return (
    <>
    <div className={styles.main}>
      <div className='container'>
        <div>
          <h2>우리가족 건강 수호 앱</h2>
          <h1>바로필</h1>
          <br />
          <ul className='check'>
            <li><strong>스마트폰</strong> 하나로 <strong>언제 어디서나</strong> 가능한 <strong>비대면 진료</strong></li>
            <li><strong>진료</strong>부터 <strong>처방</strong>, <strong>조제약 배달</strong>까지 <strong>원스톱 서비스</strong></li>
            <li>기능의학 전문 의사의 <strong>건강상담</strong>과 <strong>맞춤 영양제</strong> 설계</li>
            <li><strong>온 가족 건강 관리</strong> 도와주는 <strong>토탈 헬스케어</strong> 플랫폼</li>
          </ul>
        </div>
      </div>
    </div>
    <div className={styles.content}>
      <div className='container'>
        <div className={styles.content1}>
          <h1>바로필은 이런 서비스를 제공해요.</h1>
          <h2>바로필은 바로 받는 비대면 진료,<br />바로 받는 조제약 서비스를 제공합니다.</h2>
          <div className={styles.images}>
            {whatService.map((item)=> <div key={item.title}>
              <img src={item.src} alt={item.src} />
              <h3>{item.title}</h3>
              <h4>{item.subtitle}</h4>
            </div>)}
          </div>
        </div>
      </div>
      
      <div className={styles.content2}>
        <h1>어떤 영양제를 먹고 있나요?</h1>
        <div>
          <div className={`container ${styles.nutrient}`}>
            <img src='./images/main/nutrient_1.png' alt='nutrient_1.png' />
            <div>
              <img src='./images/main/nutrient_2.png' alt='nutrient_2.png' />
              <h3>내 몸에 꼭 맞는 영양제는 바로필에서!</h3>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <h1>바로필 이렇게 이용하세요!</h1>
        <Swiper
          navigation loop
          className={styles.carousel}
          pagination={{type: 'progressbar'}}
          autoplay={{delay: 7000, disableOnInteraction: false}}
          modules={[Navigation, Autoplay, Pagination]}
        >
          {howTo.map((item, idx)=>
            <SwiperSlide key={idx.toString()}>
              <div className={styles.carouselContent}>
                <img src={item.src} alt={item.src} />
                <h3>{item.subtitle}</h3>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </div>
    </>
  )
};

export default Main;