import styles from './index.module.css';

const Footer = ()=> {
  return (
    <div className={styles.footer}>
      <div className='container'>
        <footer>
          <div className={styles.top}>
            <a href='/about'>
              <img
                src='/images/logo/medgo-grey.png'
                alt='medgo-grey.png'
              />
            </a>
            <div>
              <div className={styles.sns}>
                {/* SNS items go here */}
              </div>
              <a href='/policy/service'>서비스 이용약관</a> &nbsp; | &nbsp; <a href='/policy/privacy'>개인정보 처리방침</a>
            </div>
          </div>
          <div className={styles.bottom}>
            회사명 : 주식회사 메드고 &nbsp; | &nbsp; 대표이사 : 홍효주
            <br />주소 : 경상북도 포항시 북구 중앙상가1길 6, 2층 202-7호 (대흥동)
            <br />사업자등록번호 : 170-88-01970
            <br />이메일 : info@baropill.com &nbsp; | &nbsp; 대표번호 : 1644-8574
            <br/><br/><span className={styles.copyright}>Copyright © MedGo Corp. All Rights Reserved.</span>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;