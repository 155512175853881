import { Helmet } from 'react-helmet';

import styles from './index.module.css';

const About = () => {
  const value = [
    '스마트폰만 있으면 가능한 비대면 진료 앱',
    '진료부터 처방, 약 배달까지 원스톱 의료서비스',
    '꼼꼼하고 정확한 퍼스널 주치의 시스템',
    '기능의학 전문의가 제공하는 건강 맞춤설계',
    '진료 후 배송까지 1시간, 바로 받는 약배달'
  ];
  const mission = [
    '바로필은 온택트(on-tact)시대, 의료 서비스의 부재가 생기지 않도록 노력하겠습니다.',
    '바로필은 시간과 공간에 구애받지 않고, 언제 어디서나, 누구나 공평하게 의료 서비스를 받을 수 있도록 지원하겠습니다.',
    '바로필은 병원의 문턱이 높다고 생각하는 이들에게 편안하고 편리한 의료서비스를 제공하겠습니다.',
    '바로필은 건강취약계층, 사회취약계층의 의료 사각지대 감소를 위해 서비스를 제공하겠습니다.',
    '바로필은 환자와 의사 모두에게 비대면 진료로 시간을 줄이되, 더욱 친절하고 효율적인 진료가 될 수 있도록 시스템을 구축하겠습니다.',
    '바로필은 정기적으로 처방약이 필요한 환자 혹은 급하게 처방약이 필요한 환자들을 위해 신속함과 정확함을 갖추겠습니다.',
    '바로필은 친환경 소재 배달봉투를 사용해 지구의 건강까지 챙기겠습니다.',
    '바로필은 환경과 사회, 가치를 고려한 ESG 경영에 힘쓰겠습니다.',
    '디지털 환경의 변화 속에서 모든 세대가 쉽고 편리하게 이용할 수 있는 토탈 헬스케어 플랫폼을 만들겠습니다.',
    '모두의 생활 속에서, 모두의 건강 안에서, 함께 호흡하는 "모두의 주치의"가 되겠습니다.'
  ]

  return (
    <>
    <Helmet>
      <title>바로필 : 메드고</title>
    </Helmet>
    
    <div className={styles.top}>
      <div className='container'>
        <div className={styles.topInner}>
          <h2 className={styles.quote}>“</h2>
          <h1 className={styles.quoted}>병원 & 약국 가지 않는 삶의 시작</h1>
          <h2 className={styles.quote}>„</h2>
        </div>
      </div>
    </div>

    <div className='container'>
      <div className={styles.main}>
        <h2>바로필은?</h2>
        <p>언택트(Untact) 시대, 사람들의 건강 편의와 의료 사각지대 없이 모든 사람이 언제 어디서나 평등하게
          <br />주치의 개념의 서비스를 받을 수 있는 것이 바로 ㈜메드고가 지향하는 비전과 가치입니다.</p>
        <p>모든 사람이 건강한 사회를 만들겠다는 일념 하나로 설립된 스마트 헬스케어 기업 ㈜메드고는
          <br />바로 받는 비대면 진료와 바로 받는 약 배달 서비스를 제공하는 바로필 앱을 2021년 7월 13일 론칭하면서
          <br />서비스의 시작을 알렸습니다.</p>
        <p>바로 나, 바로 우리 가족, 바로 세상 모든 사람들의 건강을
          <br />책임지는 토탈 헬스케어 플랫폼, 바로필을 소개합니다.</p>
        <h3>설립 2021년 1월 13일<br /><strong>대표 홍효주</strong></h3>
      </div>
    </div>

    <div className={styles.value}>
      <div className='container'>
        <div className={styles.valueInner}>        
          <div className={styles.valueContent}>
            <h2>바로필의 가치</h2>
            {value.map((item, index)=> <div key={item}>
              <h3>{(index+1).toString().padStart(2, '0')}</h3>
              <h3>{item}</h3>
            </div>)}
          </div>
          <div className={styles.valueImg}>
            <h1>Value</h1>
            <img src='./images/about/value-bg.png' alt='./images/about/value-bg.png' />
          </div>
        </div>
      </div>
    </div>

    <div className={styles.mission}>
      <div className='container'>     
          <div className={styles.missionContent}>
            <h2>바로필의 미션</h2>
            {mission.map((item, index)=> <div key={item}>
              <h3>{(index+1).toString().padStart(2, '0')}</h3>
              <h3>{item}</h3>
            </div>)}
          </div>
          <div className={styles.missionImg}>
            <h1>Mission</h1>
            <img src='./images/about/mission-bg.png' alt='./images/about/mission-bg.png' />
          </div>
      </div>
    </div>
    </>
  )
};

export default About;