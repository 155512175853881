import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { reportWebVitals, channelIo } from './modules';
import { Header, Footer } from './layouts';
import {
  Main,
  About,
  Policy,
} from './pages';
import './style.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>

    <Header />
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<Main />} />
          <Route path='about' element={<About />} />
          <Route path='policy/service' element={<Policy name='서비스 이용약관' />} />
          <Route path='policy/privacy' element={<Policy name='개인정보 처리방침' />} />

          <Route path='*' element={<Navigate replace to='/' />} />
        </Route>
      </Routes>
    </BrowserRouter>
    <Footer />

  
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// Learn more about ChannelIo: https://developers.channel.io/docs/web-quickstart
channelIo.loadScript();
channelIo.boot({
  pluginKey: '3d9ea810-fb9c-4080-8ef5-5f2741163b5d',
});