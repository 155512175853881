import React from 'react';
import styles from './index.module.css';

const Header = ()=> {
  const [route, setRoute] = React.useState('');
  React.useLayoutEffect(()=> {
    const path = window.location.pathname.split('/');
    setRoute(path[1]);
  }, []);

  const routes = [
    { en: 'About', kr: '회사소개', href: '/about' },
    { en: 'Request', kr: '외주문의', link: 'https://forms.gle/ywTjcLR1eXxNeE8G8', href: '/request' }
  ];

  return (
    <div id='header' className={styles.header}>    
      <div className='container'>
        <header>
          <a href='/'>
            <img
              className={styles.appLogo}
              src='/images/logo/baropill-colored.png'
              alt='baropill-colored.png'
            />
          </a>

          <nav>
            {routes.map((item)=>
              <a
                key={item.en}
                href={item.link||item.href}
                target={item.link ? '_blank' : '_self'}
                className={(item.href === ('/' + route)) ? styles.colored : undefined}
              >
                {item.kr}
              </a>
            )}
          </nav>
        </header>
      </div>
    </div>
  );
};

export default Header;