import { Helmet } from 'react-helmet';

import styles from './index.module.css';
import DATA from './index.json';

const Policy = (props: {
  name: '서비스 이용약관'|'개인정보 처리방침',
}) => {
  const { name='서비스 이용약관' } = props || {};
  const data: any = DATA;
  const data_ = data[name];

  return (
    <>
    <Helmet>
      <title>바로필 : {name}</title>
    </Helmet>
    
    <div className={styles.content}>
      <div className='container'>
        <h1>{name}</h1>
        <h2>{data_.description}</h2>
        {Object.keys(data_.content)?.map((item)=>
          <div key={item}>
            <h3>{item}</h3>
            <p dangerouslySetInnerHTML={{__html: data_.content[item]}} />
          </div>
        )}

        <h4>주식회사 메드고</h4>
      </div>
    </div>
    </>
  )
};

export default Policy;